<template>
  <div id="press-releases" v-if="pressReleases[pressReleases.length - 1]">
    <div class="sub_headings text-6xl"><b>Latest</b> News</div>

    <div class="flex justify-between items-center mt-20 headline">
      <div class="w-4/5 img">
        <img
          class="w-full object-cover"
          :src="pressReleases[pressReleases.length - 1].image"
        />
      </div>
      <div class="dets pl-10">
        <div class="heading font-bold text-5xl">
          {{pressReleases[pressReleases.length - 1].title}}
        </div>
        <div class="my-6 date">{{pressReleases[pressReleases.length - 1].created_at}}</div>
        <AnchorLink
          text="Read more"
          :url="'/press-releases/' + pressReleases[pressReleases.length - 1].id"
        />
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid md:grid-cols-3 gap-6 mt-20">
      <PressRelease
        v-for="pressRelease in pressReleases.slice(0, pressReleases.length - 1)"
        :key="pressRelease.id"
        :post="pressRelease"
      />
    </div>
    <div class="text-center" v-if="nextPage">
      <button @click="loadMore()" class="mt-14 p-6 px-20 bg-nasida-green text-xl text-white text-center">
        LOAD MORE
      </button>
    </div>
  </div>
</template>

<script src="./press-releases.page.js"></script>
<style src="./press-releases.page.css" scoped lang="css"></style>
