import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import AnchorLink from "../../components/anchor-link/index.vue";
import PressRelease from "../../components/press-release/index.vue";

export default defineComponent({
  components: {
    PressRelease,
    AnchorLink,
  },
  setup() {
    const store = useStore();
    const currentPage = ref(0);
    const nextPage = ref(null);

    const loadMore = () => {
      currentPage.value += 1;
      store
        .dispatch("getAllPressReleases", currentPage.value)
        .then((_nextPage) => {
          nextPage.value = !!_nextPage;
        })
        .catch(() => {
          currentPage.value -= 1;
        });
    };

    onMounted(() => {
      store.dispatch("getAllCorporations");
      loadMore();
    });

    const pressReleases = computed(
      () => store.state.pressReleasesModule.pressReleases
    );

    return { pressReleases, nextPage, loadMore };
  },
});
